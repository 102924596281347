const detector = {
    isAndroid: /Android/.test(navigator.userAgent),
    isCordova: !!window.cordova,
    isEdge: /Edge/.test(navigator.userAgent),
    isFirefox: /Firefox/.test(navigator.userAgent),
    isChrome: /Google Inc/.test(navigator.vendor),
    isChromeIOS: /CriOS/.test(navigator.userAgent),
    isChromiumBased: !!window.chrome && !/Edge/.test(navigator.userAgent),
    isIE: /Trident/.test(navigator.userAgent),
    isIOS: /(iPhone|iPad|iPod)/.test(navigator.platform),
    isOpera: /OPR/.test(navigator.userAgent),
    isSafari: /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
    isTouchScreen: ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch,
    isWebComponentsSupported: 'registerElement' in document && 'import' in document.createElement('link') && 'content' in document.createElement('template')
};

const whatIsMyBrowser = () => {
    if (detector.isOpera) {
        return 'opera';
    }
    if (detector.isFirefox) {
        return 'firefox';
    }
    if (detector.isIOS) {
        return 'safari';
    }
    if (detector.isIE) {
        return 'ie';
    }
    if (detector.isChrome || detector.isChromeIOS || detector.isChromiumBased) {
        return 'chrome';
    }
    if (detector.isEdge) {
        return 'edge';
    }
};

export {whatIsMyBrowser};
