import {apiFetch} from "./apiFetch";
import {_event} from "./_events";
import {_storage} from "./_storage";

const DEBUG = true;
let Listener = null;
let Disconnected = true;
let interval = false;
const dispatch = data => {

    DEBUG && console.warn('[MERCURE][DISPATCH]', data);

    if (data.action === 'clear-cache') {
        PSO.clearCache();
        return;
    }
    const eventName = `on_mercure_${data.action}`;
    const event = new CustomEvent(eventName, {
        detail: data.event
    })
    setTimeout(function () {
        document.dispatchEvent(event);
        _event.emmit(eventName, {event})
        DEBUG && console.warn('[MERCURE][EMMIT]', eventName, {event});
    }, 100);
}
const subscribe = (mercureUrl, token, withReservation) => {

    if (!mercureUrl) {
        return;
    }

    const url = new URL(mercureUrl);

    Listener = new EventSource(url, {
        withCredentials: true
    });

    Listener.onmessage = e => dispatch(JSON.parse(e.data));
    Listener.onerror = e => {
        Disconnected = Date.now();
        DEBUG && console.warn('[MERCURE][ERROR]', e);
    }
    Listener.onopen = e => {
        Disconnected = false;
        DEBUG && console.warn('[MERCURE][ONOPEN]', e);
    }

}

const connect = async () => {
    DEBUG && console.warn('[MERCURE][CONNECT]', 'Start')
    if (!await _storage.getItem('login')) {
        DEBUG && console.warn('[MERCURE][CONNECT]', 'Not logged in')
        return;
    }
    Disconnected && apiFetch('/subscriber')
        .then(response => response.json())
        .then(response => subscribe(response.url, response.token, response.reservation));
    interval && clearInterval(interval);
    interval = setInterval(function () {
        if (Disconnected !== false && (Disconnected === true || Disconnected < Date.now() - 10000)) {
            DEBUG && console.warn('[MERCURE][CONNECT]', 'Reconnect')
            connect();
        }
    }, 15000);
}

const disconnect = () => {
    !Disconnected && Listener.close();
    Disconnected = true;
}

export const Mercure = {
    connect,
    disconnect
}
