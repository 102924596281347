import * as $ from "jquery";
export const confirm = (message, onSuccess = () => {}, primaryClass = 'btn-primary') => {

    const $modal = $('#confirmation-modal');

    $modal.removeClass('d-none').show();

    $('p', $modal).html(message);



    $('button', $modal).addClass(primaryClass).unbind('click').bind('click', e => {
        e.preventDefault();
        $('button', $modal).removeClass(primaryClass);
        $modal.addClass('d-none').hide();
        onSuccess();
        return false;
    });

    $('a', $modal).unbind('click').bind('click', e => {
        e.preventDefault();
        $('button', $modal).removeClass(primaryClass);
        $modal.addClass('d-none').hide();
        return false;
    });

}
