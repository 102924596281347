import * as $ from "jquery";
import moment from "moment";

var Menu = {};
Menu.init = function() {
    let time = moment();
    $('#menu-day-name').html(time.locale('pl').format('dddd'));
    $('#menu-date').html(time.locale('pl').format('D MMMM'));
    $('#menu-year').html(time.format('YYYY'));
};
if (document.querySelector('[data-menu]')) {
    Menu.init();
};
