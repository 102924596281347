import * as $ from "jquery";
import "jquery.cookie";
// import ics from "./lib/ics";
import moment from "moment";
import {GOOLE_MAP_API_KEY} from "./_variables";
// import {SimpleStorage, SimpleStorageItem} from "./SimpleStorage";
import Mercure from "./_mercure";
// import toastr from "toastr";
// import {Storage} from "./Storage";
// import {Events} from "./Events";
import {_storage} from "./_storage";
import {_event} from "./_events";
import {_navigation} from "./_navigation";
moment.locale('pl');

let PSO = {};
PSO.GOOGLE_API_key = 'AIzaSyAL59z18hbuEtef_gIllyB2OckoXFePU1g';
PSO.cache_timeout = 300;
PSO.car = undefined;
// PSO.deposits = undefined;
PSO.system = undefined;
PSO.fleet = undefined;
PSO.workshops = [];

// PSO.storage = new SimpleStorage();
// PSO.storage.add(new SimpleStorageItem('fleet', () => API.asyncGetCall('get-fleet')));
// PSO.storage.add(new SimpleStorageItem('relocationEnabled', () => API.asyncGetCall('get-relocation-enabled')));
// PSO.storage.add(new SimpleStorageItem('hasReservation'));
// PSO.storage.get('hasReservation').getItem().setLifetime(10);
// PSO.storage.add(new SimpleStorageItem('geocoding'));
// PSO.storage.add(new SimpleStorageItem('relocations', () => API.asyncGetCall('get-relocations')));
// PSO.storage.add(new SimpleStorageItem('deposits', () => API.asyncGetCall('get-deposits')));


PSO.getFleetImage = () => new Promise((resolve, reject) => {
    PSO.getFleet(fleet => {
        if (fleet.nip) {
            resolve('https://files.psoflota.pl/logos/fleet/' + fleet.nip.replace(/[^\d]+/g, '') + '.png');
        }
    })
});
PSO.imageExists = src => new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(src);
    img.onerror = () => reject();
    img.src = src;
})
PSO.clearData = () => {
    _storage.removeItem('car');
    PSO.clearCache();
};
PSO.clearCache = () => {
    _event.emmit(_event.ON_CACHE_CLEAR);
}
PSO.initCookieModal = function() {
    if (typeof $.cookie('cookie-agreement') === 'undefined') {
        $('#cookies-modal').fadeIn();
    }
};
PSO.confirmCookies = function() {
    $.cookie('cookie-agreement', true, {expires: 365});
    $('#cookies-modal').fadeOut();
};
PSO.logout = async function(e) {
    e && e.preventDefault();
    await _storage.clear();
    await fetch('/logout');
    _event.emmit(_event.ON_LOGOUT);
    return false;
};
PSO.lightLogout = async function(e) {
    e && e.preventDefault();
    await _storage.resetWithCallbacks();
    window.location.reload();
}
PSO.gotoDash = async () => {
    _navigation.changePage('dash');
    // if (window.location.pathname.match(/^\/login/)) {
    //     PSO.logout();
    // } else if (await PSO.isLoggedIn()) {
    //      window.location.href = '/dash.html';
    // }
}
PSO.isLoggedIn = async () => {
    let login = await PSO.getLogin();
    return login;
}
PSO.geocoding = async function(phrase, callback) {
    phrase = phrase.replace(' ', '+');
    let geocoding = await _storage.getItem('geocoding') || {};
    if (phrase in geocoding) {
        return callback(geocoding[phrase]);
    }
        $.ajax('https://maps.googleapis.com/maps/api/geocode/json?address=' + phrase + '&key=' + GOOLE_MAP_API_KEY + '&language=pl', {
            method: 'GET',
            success: function (response) {
                geocoding[phrase] = response;
                _storage.setItem('geocoding', geocoding);
                callback(response);
            },
            error: function() {

                callback(null);
            }
        });
};
PSO.getInitialData = async function(callback) {

    PSO.car = await _storage.getItem('car');
    PSO.fleet = await _storage.getItem('fleet');
    callback();

    // API.call('GET', 'get-initial-data', {}, function(response) {
    //
    //     const timestamp = moment().unix().toString();
    //
    //     PSO.car = response.car;
    //     Storage.setItem('car', PSO.car);
    //
    //     // PSO.deposits = response.deposits;
    //     // localStorage.setItem('deposits', JSON.stringify(PSO.deposits));
    //     // localStorage.setItem('deposit_refreshed', timestamp);
    //
    //     PSO.fleet = response.fleet;
    //     Storage.setItem('fleet', PSO.fleet);
    //
    //     if (typeof callback !== undefined) {
    //         callback();
    //     }
    // });
};
PSO.setLogin = async val => {
    let login = val ? 1 : 0;
    await _storage.setItem('login', login);
    if (!login) {
        _storage.setItem('auth', {});
    }
}
PSO.getLogin = async () => {
    let login = parseInt(await _storage.getItem('login'))
    return login;
}
PSO.getCar = async function(callback, force) {
    PSO.car = await _storage.getItem('car');
    return callback();
    // if (typeof force === 'undefined') force = false;
    // if (
    //     !force &&
    //     await Storage.getItem('car') !== null
    // ) {
    //     PSO.car = await Storage.getItem('car');
    //     // PSO.checkReservation(PSO.car.carId);
    //     callback();
    //     return;
    // }
    // API.call( 'GET', 'get-car', {}, function(response) {
    //     PSO.car = response;
    //     // PSO.checkReservation(PSO.car.carId);
    //     Storage.setItem('car', response);
    //     callback();
    // }, function() {
    //     callback();
    // });
};
PSO.getCarHistory = async function(callback, force) {
    PSO.car_history = await _storage.getItem('car-history');
    return callback();
    //
    // if (await Storage.getItem('car-history') !== null) {
    //
    // }
    // if (typeof force === 'undefined') force = false;
    // if (
    //     !force &&
    //     await Storage.getItem('car-history') !== null
    // ) {
    //     PSO.car_history = await Storage.getItem('car-history');
    //     // PSO.checkReservation(PSO.car.carId);
    //     callback();
    //     return;
    // }
    // API.call( 'GET', 'car-history', {}, function(response) {
    //     PSO.car_history = response;
    //     Storage.setItem('car-history', response);
    //     callback();
    // }, function() {
    //     callback();
    // });
};
// PSO.getDeposits = function(callback, force) {
//
//
//
//     if (typeof force === 'undefined') force = false;
//     if (
//         !force &&
//         parseInt(localStorage.getItem('deposit_refreshed')) + PSO.cache_timeout > moment().unix() &&
//         localStorage.getItem('deposits') !== null
//     ) {
//         PSO.deposits = JSON.parse(localStorage.getItem('deposits'));
//         callback();
//         return;
//     }
//     API.call('GET', 'get-deposits', {}, function(response) {
//         PSO.deposits = response;
//         localStorage.setItem('deposits', JSON.stringify(response));
//         localStorage.setItem('deposit_refreshed', moment().unix().toString());
//         callback();
//     }, function() {
//         callback();
//     })
// };
PSO.getFleet = callback => _storage.getItem('fleet').then(fleet => {
    PSO.fleet = fleet;
    if (fleet.status === false && fleet.error === 'User not found' || fleet === undefined || fleet === 'undefined') {
        PSO.lightLogout();
    }
    callback(fleet);
}).catch(() => PSO.lightLogout());

PSO.depositStatuses = {
    'depozyt' : 'W serwisie',
    'relokacja' : 'W trakcie relokacji',
    'poczekalnia': 'W serwisie',
    'rezerwacja': 'W serwisie'
};
PSO.depositStatus = deposit => PSO.depositStatuses[deposit] || deposit;

//     function(callback, force) {
//     if (typeof force === 'undefined') force = false;
//     if (
//         !force &&
//         parseInt(localStorage.getItem('fleet_refreshed')) + PSO.cache_timeout > moment().unix() &&
//         localStorage.getItem('fleet') !== null
//     ) {
//         PSO.fleet = JSON.parse(localStorage.getItem('fleet'));
//         callback();
//         return;
//     }
//     API.call('GET', 'get-fleet', {}, function(response) {
//         PSO.fleet = response;
//         localStorage.setItem('fleet', JSON.stringify(response));
//         localStorage.setItem('fleet_refreshed', moment().unix().toString());
//         if (typeof callback !== undefined) {
//             callback();
//         }
//     });
// };
PSO.getSystem = async function(callback) {
    PSO.system = await _storage.getItem('systemSettings')
    return callback();
    // API.call('GET', 'get-system-settings', {}, function(response) {
    //     PSO.system = response;
    //     if (typeof callback !== 'undefined') {
    //         callback();
    //     }
    // });
};
PSO.groupDeposit = function(collection) {
    let result = [];
    if (typeof collection === 'undefined') {
        return result;
    }
    let values = [];
    for (let i = 0; i < collection.length; i++) {
        let val = collection[i].verbalized;
        if (typeof collection[i].workshop !== 'undefined' && collection[i].workshop !== null) val += collection[i].workshop.nameShort;
        let index = values.indexOf(val);
        if (index !== -1) {
            result[index].push(collection[i]);
        } else {
            values.push(val);
            result.push([collection[i]]);
        }
    }
    return result;
};
PSO.getNavigationLinkFromLatLng = (lat, lng) => PSO.getNavigationLink(`${lat},${lng}`)
PSO.getNavigationLink = addr => `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(addr)}`;
// PSO.addReservationToCalendar = reservation => {
//
//     // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
//     //
//     // if (isIOS) {
//         const cal = ics();
//         cal.addEvent('Wymiana Opon', '', `${reservation.name} - ${reservation.street}, ${reservation.city}`,
//             reservation.fromDate, reservation.toDate);
//         cal.download();
//     // } else {
//     //     const url = 'https://www.google.com/calendar/render?action=TEMPLATE'
//     //         + '&text=' + encodeURI('Wymiana Opon')
//     //         + '&location=' + encodeURI(`${reservation.name} - ${reservation.street}, ${reservation.city}`)
//     //         + '&dates=' + encodeURI(`${moment(reservation.fromDate).format('YYYYMMDDTHHmmss')}/${moment(reservation.toDate).format('YYYYMMDDTHHmmss')}`)
//     //     var win = window.open(url, '_blank');
//     //     win.focus();
//     // }
//
// }
PSO.abortReservation = carId => new Promise((resolve, reject) => {
    API.asyncPostCall(`car-abort-reservation`)
        .then(response => {
            _event.emmit(_event.ON_EVENT_CHANGE);
            // Mercure.disconnect();
            resolve(response);
        })
        .catch(reject);
});
PSO.init = async function() {
    // if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker.register('/sw.js');
    // }
    PSO.initCookieModal();

    //$.removeCookie('cookie-agreement');
    $('.modal-close').click(function() { $('.modal-background').fadeOut(); });
    $('#cookies-agree').click(function() { PSO.confirmCookies(); });
    // $('.settings').click(PSO.logout);
    if (await PSO.isLoggedIn()) {
        PSO.getFleetImage().then(
            img => PSO.imageExists(img)
                                            .then(src => {
                                                $('.fleet-logo').css('background-image', `url('${src}')`);
                                                $('.carefleet-logo').text(PSO.fleet.name).css('background-image', '');
                                                $( window ).width() >= 768 && $('.carefleet-logo').hide();
                                            })
                                            .catch(() => $('.carefleet-logo').text(PSO.fleet.name))
        );
        if (navigator.serviceWorker) {
            navigator.serviceWorker.ready.then( registration => {
                registration.active.postMessage({'action' : 'appInit'});
            });
        }
    };
};
PSO.grayScaleMap = map => {
    var stylez = [
        {
            featureType: "all",
            elementType: "all",
            stylers: [
                { saturation: -100 } // <-- THIS
            ]
        }
    ];

    var mapType = new google.maps.StyledMapType(stylez, { name:"Grayscale" });
    map.mapTypes.set('tehgrayz', mapType);
    map.setMapTypeId('tehgrayz');
}
// PSO._boolTypeStorage = key => {
//     return {
//         get : async () => await Storage.getItem(key) ? true : false,
//         set : async val => await Storage.setItem(key, val ? 1 : 0),
//         remove: async () => await Storage.removeItem(key),
//         exists: async () => await Storage.getItem(key) !== null
//     };
// }
// PSO.mercure = Mercure;
//PSO.relocationEnabled = PSO.storage.get('relocationEnabled');
// PSO.onRelocationEnabled = (resolve, reject) => {
//     resolve = resolve || function(){};
//     reject  = reject || function(){};
//     if (PSO.relocationEnabled.exists()) {
//         if (PSO.relocationEnabled.get()) {
//             resolve();
//         } else {
//             reject();
//         }
//     } else {
//         API.asyncGetCall('get-relocation-enabled').then(response => {
//             PSO.relocationEnabled.set(response);
//             response ? resolve() : reject;
//         }).catch(() => {
//             PSO.relocationEnabled.set(false);
//             reject();
//         })
//     }
// }




let API = {};
API._xhrs = {};
API.abortAllCalls = () => Object.keys(API._xhrs).forEach(API.abortCall);
API.abortCall = url => (url in API._xhrs) && (API._xhrs[url].abort() || delete API._xhrs[url]);
API.call = function(method, url, data, successCallback, errorCallback, headers, opts) {

    let address_prefix = '/';

    var args = {
        method: method,
        url: address_prefix + url,
        headers: $.extend({}, headers),
        data: data,
        success: function(response, status, xhr) {
            if (typeof successCallback != 'undefined' && successCallback !== null) {
                successCallback(response);
            }
        },
        error: function(response, status, status_type) {
            if (status_type === 'Unauthorized' && url.indexOf('login') === -1 && url.indexOf('logout') === -1) {

                PSO.logout();
            }
            if (typeof errorCallback != 'undefined' && errorCallback !== null) {
                errorCallback(response);
            }
        }
    };

    if (data instanceof FormData) {
        args = $.extend(args, {
            processData: false,
            contentType: false,
            enctype: 'multipart/form-data'
        });
    }

    API._xhrs[url] = $.ajax(args, opts);
};
API.asyncCall = (method, url, data, headers, opts) => new Promise((resolve, reject) => {
    API.call(method, url, data, resolve, reject, headers, opts);
});
API.asyncGetCall = (url, data = {}, headers , opts) => API.asyncCall('GET', url, data, headers, opts);
API.asyncPostCall = ( url, data = {}, headers, opts) => API.asyncCall('POST', url, data, headers, opts);
// PSO.checkReservation = async (carId) => await Storage.getItem('hasReservation'))
//                              && API.asyncGetCall(`car-has-reservation`)
//                                      .then(response => {
//                                          Storage.setItem('hasReservation', response.reservation);
//                                          if (!response.reservation) {
//                                              Storage.removeItem('reservation');
//                                              Storage.removeItem('rejection');
//                                          }
//                                      });
PSO.getReservation = (force = false) => new Promise(async (resolve, reject) => {

    let reservation = await _storage.getItem('reservation');
    if (!force && reservation === false) {
        return reject();
    }
    if (!force && reservation) {

        if (Array.isArray(reservation) && reservation.length === 0) {
            return reject();
        }
        (reservation &&  moment(reservation.toDate).isAfter()) ? (resolve(reservation)) : reject();
    } else {
        return reject();
        //
        // API.asyncGetCall( `car-future-reservation`).then(response => {
        //
        //     if (response.reservation && moment(response.reservation.toDate).isAfter()) {
        //         Storage.setItem('reservation', response.reservation);
        //         Storage.removeItem('rejection');
        //         Storage.setItem('hasReservation', 1);
        //         Mercure.connect();
        //         if (navigator.serviceWorker) {
        //             navigator.serviceWorker.ready.then( registration => {
        //                 registration.active.postMessage({'action' : 'appInit'});
        //             });
        //         }
        //         resolve(response.reservation);
        //     } else {
        //         Storage.setItem('reservation', false);
        //         Mercure.disconnect();
        //         reject()
        //     }
        // }).catch(reject)
    }
});
PSO.getRejection = (force = false) => new Promise(async (resolve, reject) => {
    let rejection = await _storage.getItem('rejection');
    return rejection ? resolve(rejection) : reject();

    // if (!force && rejection) {
    //     return resolve(rejection);
    // }
    // if (!force && rejection === false) {
    //     return reject();
    // }
    // API.asyncGetCall('event-rejection').then(response => {
    //     if ('garage' in response) {
    //         .setItem('rejection', response);
    //         return resolve(response);
    //     } else {
    //         Storage.setItem('rejection', false);
    //     }
    //     reject();
    // })
})
PSO.workshopToText = (workshop) => workshop.nameShort || workshop.name + ', ' + workshop.street + ', ' + workshop.city;
PSO.workshopToStrictSearchText = (workshop) => 'Polska' + ', ' + workshop.street + ', ' + workshop.city;
PSO.workshopGetCoords = workshop => new Promise((resolve, reject) => {
    if (workshop.latitude && workshop.longitude) {
        return resolve({
            lat: workshop.latitude,
            lng: workshop.longitude
        })
    } else if (workshop.coordinates) {
        const match = workshop.coordinates.match(/^([\d\.]+)[^\d\.]+([\d\.]+)$/)
        if (match) {
            return resolve({
                lat: parseFloat(match[1]),
                lng: parseFloat(match[2])
            });
        }
    }
        PSO.geocoding(PSO.workshopToStrictSearchText(workshop), function(response) {
            if (response && response.results && response.results.length > 0) {
                resolve({
                    lat: response.results[0].geometry.location.lat,
                    lng: response.results[0].geometry.location.lng,
                })
            } else {
                reject();
            }
        });

});

PSO.preloader = {
    delay: 500,
    isStarted : null,
    start : () => {

        if (PSO.preloader.isStarted !== null) {
            return;
        }
        PSO.preloader.isStarted = setTimeout(() => {
            PSO.preloader.isStarted = null;
            $("#main-preloader").show()
        }, PSO.preloader.delay);
    },
    stop : () => {

        if (PSO.preloader.isStarted !== null) {
            clearTimeout(PSO.preloader.isStarted);
        }
        $("#main-preloader").hide()
        PSO.preloader.isStarted = null;
    }
}

// Maps helpers.

function boundsToZoom(ne, sw) {
    let GLOBE_WIDTH = 256; // a constant in Google's map projection
    let angle = ne.lng - sw.lng;
    if (angle < 0) {
        angle += 360;
    }
    return Math.round(Math.log($('#map').width() * 360 / angle / GLOBE_WIDTH) / Math.LN2);
}

PSO.depositModel = deposits => {
    const services = (deposits || []).reduce((keep, item) => {
        const id = (item.workshop ? item.workshop.workshopId: 0) ;
        keep[id] = id ? item.workshop : 1;
        return keep;
    }, {});
    return {
        getDeposits: () => deposits,
        multipleServices: () => Object.keys(services).length > 1,
        getServices: () => services,
        getByServices: () => deposits.reduce((keep, item) => {
            const id = (item.workshop ? item.workshop.workshopId: 0) ;
            if (id in keep) {
                keep[id].push(item);
            } else {
                keep[id] = [item];
            }
            return keep;
        }, {}),
        groupDeposit: () => PSO.groupDeposit(deposits),
        statuses: () => deposits.reduce((keep, item) => {
            if (keep.indexOf(item.status) === -1) {
                keep.push(item.status);
            }
            return keep;
        }, []),
    }
}

export {PSO, API, boundsToZoom};
