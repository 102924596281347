import {PSO} from "./_PSO";
import {_storage} from "./_storage";


const Deposits = () => new Promise(async (resolve, reject) => {
   if (!await PSO.isLoggedIn()) {
        return;
   }
   try {
       let deposits = await _storage.getItem('deposits');
       const relocations = await _storage.getItem('relocations', []);
       (relocations || []).forEach((relocation, idx) => {
           deposits[idx].status = relocation.deposit.status;
           deposits[idx].workshop = relocation.workshopTo;
       })
       resolve(deposits, relocations);
   } catch {
       reject();
   }
});

export default Deposits;
