import localforage from 'localforage';
import {Events} from "./Events";
import {API, PSO} from "./PSO";
import {apiFetch} from "./apiFetch";

const _Storage = localforage.createInstance({
    driver: localforage.INDEXEDDB,
    name: 'PSO',
    version: 1.0
})

const Callbacks = {};
const Ttls = {};

export const Storage = {
    debug: true,
    env: '[FR]',
    defaultTtl: 300000,
    setTtl: (key, ttl) => Ttls[key] = ttl,
    getTtl: key => key in Ttls ? Ttls[key] : Storage.defaultTtl,
    setCallback: (key, cb) => {
        Callbacks[key] = cb;
    },
    invalidateItem: async key => {
        Storage.DEBUG && console.warn(Storage.env, '[Storage] invalidate', key);
        await _Storage.setItem(`${key}_ttl`, 0);
    },
    resetItem: async key => {
        Storage.DEBUG && console.warn(Storage.env, '[Storage] reset', key);
        await Storage.invalidateItem(key);
        await Storage.getItem(key);
    },
    getItem: async key => {
        // Storage.DEBUG && console.warn(Storage.env, '[Storage] get', key);
        const time = Date.now();
        const ttl = await _Storage.getItem(`${key}_ttl`) || 0;
        let value = null;

        if (ttl > time || !await _Storage.getItem('login')) {
            value = await _Storage.getItem(key);
        } else if (key in Callbacks) {
            try {
                value = await Callbacks[key]();
                await Storage.setItem(key, value);
            } catch (err) {
                console.warn(err);
                value = await _Storage.getItem(key);
            }
        }

        Events.emmit(Events.ON_ELEMENT_LOADED, {
            name: key,
            value
        });

        return value;
    },
    setItem: async (key, value) => {
        Storage.DEBUG && console.warn(Storage.env, '[Storage] set', key);
        const time = Date.now();
        await _Storage.setItem(`${key}_ttl`, time + Storage.getTtl(key));
        await _Storage.setItem(key, value);
        Events.emmit(Events.ON_ELEMENT_SET, {
            name: key,
            value
        });

    },
    removeItem: async (key) => {
        Storage.DEBUG && console.warn(Storage.env, '[Storage] remove', key);
        await _Storage.removeItem(`${key}_ttl`);
        await _Storage.removeItem(key);
        Events.emmit(Events.ON_ELEMENT_REMOVE, {
            name: key
        });

    },
    invalidateEventRelated: async () => {
        await Storage.invalidateItem('reservation');
        await Storage.invalidateItem('rejection');
        await Storage.invalidateItem('car-history');
        await Storage.invalidateItem('hasReservation');
        await Storage.invalidateItem('forNewTermClosed');
    },
    invalidateDepositRelated: async () => {
        await Storage.invalidateItem('car-history');
        await Storage.invalidateItem('deposits');
        await Storage.invalidateItem('relocationEnabled');
        await Storage.invalidateItem('relocations');
    },
    clear: _Storage.clear
}

Storage.setTtl('token', 24 * 3600000000);
Storage.setTtl('login', 24 * 3600000000);
Storage.setTtl('reservation', 15000);
Storage.setTtl('rejection', 15000);
Storage.setTtl('forRating', 15000);

Storage.setCallback(
    'fleet',
    async () => await apiFetch('/get-fleet').then(response => response.json())
);
Storage.setCallback(
    'relocationEnabled',
    async () => await apiFetch('/get-relocation-enabled').then(response => response.json())
);
Storage.setCallback(
    'reservation',
    async () => {
        const response = await apiFetch('/car-future-reservation').then(response => response.json())
        return response.reservation ? response.reservation : false;
    }
);
Storage.setCallback(
    'forRating',
    async () => await apiFetch('/get-for-rating').then(response => response.json())
);
Storage.setCallback(
    'fleet',
    async () => await apiFetch('/get-relocation-enabled').then(response => response.json())
);
Storage.setCallback(
    'relocations',
    async () => await apiFetch('/get-relocations').then(response => response.json())
);
Storage.setCallback(
    'deposits',
    async () => {

        return await apiFetch('/get-deposits').then(response => response.json());
    }
);
Storage.setCallback(
    'car',
    async () => await apiFetch('/get-car').then(response => response.json())
);
Storage.setCallback(
    'systemSettings',
    async () => await apiFetch('/get-system-settings').then(response => response.json())
);
Storage.setCallback(
    'workshops',
    async () => await apiFetch('/get-workshops').then(response => response.json())
);
Storage.setCallback(
    'car-history',
    async () => await apiFetch('/car-history').then(response => response.json())
);
Storage.setCallback(
    'rejection',
    async () => {
        const rejection = await apiFetch('/event-rejection').then(response => response.json())
        return 'garage' in rejection ? rejection : false;
    }
)
Storage.setCallback(
    'hasReservation',
    async () => await Storage.getItem('reservation') !== false
);

// Events.listen([Events.ON_INITIALIZE], async () => {
//     Storage.resetItem('reservation');
//     Storage.resetItem('rejection');
//     Storage.resetItem('car-history');
//     Storage.resetItem('hasReservation');
// });

// Events.listen(Events.ON_HIDDEN, async () => {
//     Storage.invalidateEventRelated();
//     Storage.invalidateDepositRelated();
// }, true)

Events.listen(Events.ON_VISIBLE, async () => {
    Storage.resetItem('reservation');
    Storage.resetItem('rejection');
    Storage.resetItem('car-history');
    Storage.resetItem('hasReservation');
    Storage.resetItem('deposits');
    Storage.resetItem('relocationEnabled');
    Storage.resetItem('relocations');
}, true)

Events.listen(Events.ON_LOGOUT, async () => {
    PSO.preloader.start();
    try {
        await API.asyncGetCall('logout');
    } finally {
        if (!window.location.pathname.match(/^\/login/)) {
            window.location.href = '/login'
        }
    }
    PSO.preloader.stop();
});
Events.listen(Events.ON_LOGOUT, async () => {
    await Storage.clear();
}, true);

Events.listen([Events.ON_TERMINATE, Events.ON_LOGIN], async () => {
    for(let key in Callbacks) {
        await Storage.getItem(key);
    }
}, true);

Events.listen(Events.ON_CACHE_CLEAR, async () => {
    const re = /(token|_ttl|_timestamp)$/;
    keys.filter((name) => !re.test(name))
        .forEach(async name => {
            await Storage.resetItem(name);
        });
}, true);
