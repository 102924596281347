import * as $ from "jquery";
import {PSO, API} from "./_PSO.js";
import {_event} from "./_events";

var Contact = {};
Contact.files = [];
Contact.$contact_subject = undefined;
Contact.$contact_content = undefined;
Contact.$files_input = undefined;
Contact.$files_wrapper = undefined;
Contact.$file_template = undefined;
Contact.send = function() {
    $('#contact-success').addClass('error');
    $('.error').hide();
    let valid = true;
    if (Contact.$contact_subject.val() === null) {
        $('#subject-error').fadeIn();
        valid = false;
    }
    if (Contact.$contact_content.val() === '') {
        $('#content-error').fadeIn();
        valid = false;
    }
    if (!valid) return;

    let data = new FormData();
    data.append('subject', Contact.$contact_subject.val());
    data.append('content', Contact.$contact_content.val());
    for (let i in Contact.files) {
        data.append('files[]', Contact.files[i]);
    }

    PSO.preloader.start();
    API.call('POST', 'contact', data, function() {
        PSO.preloader.stop();
        Contact.reset();
        $('#contact-success').removeClass('error').fadeIn();
        setTimeout(function () {
            $('#contact-success').addClass('error')
        }, 10000);
    }, function () {
        PSO.preloader.stop();
        $('#contact-error').fadeIn();
    });
};
Contact.reset = function() {
    Contact.$contact_content.val('');
    Contact.$contact_subject.val('');
    Contact.files = [];
    Contact.renderFiles();
    $('.error').hide();
}
Contact.renderFiles = function() {
    Contact.$files_wrapper.html('');
    for (let i in Contact.files) {
        let $file = Contact.$file_template.clone();
        $file.removeClass('template');
        $file.find('.file-name').html(Contact.files[i].name);
        $file.find('.file-size').html(Math.ceil(Contact.files[i].size / 1000) + 'kb');
        $file.find('.remove-file').click(function() {
            delete Contact.files[i];
            Contact.renderFiles();
        });
        Contact.$files_wrapper.append($file);
    }
};
Contact.fileAdded = function() {
    $('.error').hide();
    let file = Contact.$files_input.prop('files')[0];
    if (['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'application/pdf'].indexOf(file.type) === -1) {
        $('#upload-error').fadeIn();
        return;
    }
    if (Contact.files.length >= 4) {
        $('#upload-error-max').fadeIn();
        return;
    }
    if (file.size/1024/1024 > 15) {
        $('#upload-error-large').fadeIn();
        return;
    }
    let totalSize = Contact.files.reduce((carry, file) => carry + file.size, file.size);
    if (totalSize/1024/1024 > 25) {
        $('#upload-error-large-all').fadeIn();
        return;
    }

    Contact.files.push(Contact.$files_input.prop('files')[0]);
    Contact.renderFiles();
};
Contact.removeFile = function(name) {
    for (let i in Contact.files) {
        if (Contact.files[i].name === name) {
            Contact.files = Contact.files.splice(i, 1);
            break;
        }
    }
    Contact.renderFiles();
};
Contact.initialized = false;
Contact.init = function() {
    if (Contact.initialized) return false;
    Contact.initialized = true;
    Contact.$contact_subject = $('#contact-subject');
    Contact.$contact_content = $('#contact-content');
    Contact.$files_wrapper = $('#files-wrapper');
    Contact.$file_template = $('#file-template');
    Contact.$files_input = $('#files-input');
    Contact.$files_input.change(Contact.fileAdded);
    $('#upload-button').click(function() {
        Contact.$files_input.trigger('click');
    });
    $('#contact-send').click(Contact.send);
};
_event.addListener(_event.ON_LOGIN, Contact.init);
_event.addListener(_event.ON_PAGE_CHANGE, data => {
    if (data.page !== 'contact') return;
    Contact.init();
    Contact.reset();
});

