const $ = require('jquery');

const change = (selector, step) => {
    const $item = $(selector);
    const before = $item.data('mini-preloader') ? parseInt($item.data('mini-preloader')) : 0;
    const current = Math.max(0, before + step);
    $item.data('mini-preloader', current);

    if (current > 0) {
        $item.addClass('mini-preloader');
    } else {
        $item.removeClass('mini-preloader');
    }
    return true;
}
const add = selector => change(selector, 1);
const set = (selector, nr) => $(selector).data('mini-preloader', nr - 1) && add(selector);
const remove = selector => change(selector, -1);

export const miniPreloader = {
    add,
    remove,
    set
}
