const ON_ONLINE= 'on_online';
const ON_OFFLINE= 'on_offline';
const ON_LOGIN= 'on_login';
const ON_LOGOUT= 'on_logout';
const ON_VISIBLE= 'on_visible';
const ON_HIDDEN= 'on_hidden';
const ON_INITIALIZE= 'on_initialize';
const ON_CACHE_CLEAR= 'on_cache_clear';
const ON_TERMINATE= 'on_terminate';
const ON_MERCURE_RELOCATION_END= 'on_mercure_relocation-end';
const ON_MERCURE_UPDATE= 'on_mercure_update';
const ON_MERCURE_DELETE= 'on_mercure_delete';
const ON_MERCURE_REJECT= 'on_mercure_reject';
const ON_PAGE_CHANGE = 'on_page_change';
const ON_DEPOSIT_CHANGE = 'on_deposit_change';
const ON_EVENT_CHANGE = 'on_event_change';
const DEBUG = true;
const listeners = {};
const addListener = (name, listener) => {
    if (Array.isArray(name)) {
        return name.map(_name => addListener(_name, listener))
    }
    DEBUG && console.warn('[EVENT][ADD LISTENER]', name, `${listener}`);
    (name in listeners ) || (listeners[name] = []);
    (typeof listener === 'function') && listeners[name].push(listener);
}
const emmit = (name, data = null) => {
    DEBUG && console.warn('[EVENT][EMMIT]', name, data);
    return (name in listeners) && listeners[name].map(callback => {
        DEBUG && console.warn('[EVENT][EXECUTE]', name, `${callback}`);
        callback(data)
    });
}

export const _event = {
    ON_CACHE_CLEAR,
    ON_LOGIN,
    ON_LOGOUT,
    ON_MERCURE_DELETE,
    ON_MERCURE_REJECT,
    ON_MERCURE_UPDATE,
    ON_MERCURE_RELOCATION_END,
    ON_PAGE_CHANGE,
    ON_VISIBLE,
    ON_HIDDEN,
    ON_DEPOSIT_CHANGE,
    ON_EVENT_CHANGE,
    addListener,
    emmit
}
