import moment from "moment";

moment.locale('pl');

let freeDays = null;

const free = [
    [1, 1],
    [6, 1],
    [1, 5],
    [3, 5],
    [15, 8],
    [1, 11],
    [11, 11],
    [25, 12],
    [26, 12],
];

const loadFreeDays = () => {
    let freeDays = [];
    const year = (new Date()).getFullYear();
    free.map(item => freeDays.push(moment().set('year', year).set('month', item[1]).set('day', item[0])));
    const thisEaster = easter((new Date()).getFullYear());
    freeDays.push(thisEaster); //wielkanoc
    freeDays.push(thisEaster.clone().add('day', 1)); //drugi dzien
    freeDays.push(thisEaster.clone().add('day', 49)); //zielone swiatki
    freeDays.push(thisEaster.clone().add('day', 60)); //boze cialo
    return freeDays;
}


const easter = year => {
    let a, b, c, d, e, f, g, h, i, k, l, m, p;

    a = year % 19;
    b = Math.floor(year / 100);
    c = year % 100;
    d = Math.floor(b / 4);
    e = b % 4;
    f = Math.floor((b + 8) / 25);
    g = Math.floor((b - f + 1) / 3);
    h = (19 * a + b - d - g + 15) % 30;
    i = Math.floor(c / 4);
    k = c % 4;
    l = (32 + 2 * e + 2 * i - h - k) % 7;
    m = Math.floor((a + 11 * h + 22 * l) / 451);
    p = (h + l - 7 * m + 114) % 31;

    const day = p + 1;
    const month = Math.floor((h + l - 7 * m + 114) / 31);

    return moment().set('year', year).set('month', month).set('day', day);

}


const isFreeDay = mmnt => {
    if (freeDays === null) {
        freeDays = loadFreeDays();
    }
    return freeDays.reduce((carry, current) => carry || current.isSame(mmnt, 'day'), false);
}

export {isFreeDay};
