import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import {Events} from "./Events";
import {apiFetch} from "./apiFetch";
import {Storage} from "./Storage";

let Listener = null;
const dispatch = data => {
    if (data.action === 'clear-cache') {
        PSO.clearCache();
        return;
    }
    const eventName = `on_mercure_${data.action}`;
    const event = new CustomEvent(eventName, {
        detail: data.event
    })

    setTimeout(function() {
        document.dispatchEvent(event);
        Events.emmit(eventName, {event})
    }, 100);
}
const subscribe = (mercureUrl, token, withReservation) => {

    if (!mercureUrl) {
        return;
    }

    const url = new URL(mercureUrl);

    Listener = new EventSource(url, {
        withCredentials: true
    });

    Listener.onmessage = e => dispatch(JSON.parse(e.data));
    Listener.onerror = e => {
        console.error(e);
        // setTimeout(() => {
        //     subscribe(mercureUrl, token);
        // }, 200);
    }
    Listener.onopen = e => console.warn(e);

    // if (withReservation) {
    //     url.searchParams.append('topic', '/events/{id}');
    // }
    // url.searchParams.append('topic', '/garages')
    //
    // try {
    //     Listener = new EventSourcePolyfill(url, {
    //         withCredentials: true,
    //         headers: {
    //             'Authorization': `Bearer ${token}`
    //         }
    //     });
    //
    //     Listener.onmessage = e => dispatch(JSON.parse(e.data));
    //     Listener.onerror = e => {
    //         Listener = false;

    //     }
    //     Listener.onopen = e => console.warn(e);
    // } catch {
    //     Listener = false;
    // }
}

const connect = () => {
    setTimeout(async () => {
        if (!await Storage.getItem('login')) {
            return;
        }
        Listener === null && apiFetch('/subscriber')
            .then(response => response.json())
            .then(response => subscribe(response.url, response.token, response.reservation));
    }, 2000);
}

const disconnect = () => {
    setTimeout(() => {
        // console.info('disconnect', Listener);
        (Listener instanceof EventSource) && Listener.close();
        Listener = null;
    }, 1000)
}

const Mercure = {
    connect,
    disconnect
}

export default Mercure;
