const $ = require('jquery');

$(function (){
    $('.ratings').each(function() {
        const $ratings = $(this);
        const $star = $('.star', $ratings);

        $star.on('click', function () {
            $('.selected', $ratings).each(function () {
                $(this).removeClass('selected');
            });
            $(this).addClass('selected');
            $ratings.parent().find('select').val(5 - $(this).index())
            $ratings.parent().find('label').css('color', '');
        });
        $star.eq(5 - $ratings.parent().find('select').val()).trigger('click');
    });

    $('#rate_form_username').keyup(function() {
        if ($(this).val().length) {
            $(this).css('border-color', '').prev().css('color', '');
        }  else {
            $(this).css('border-color', 'red').prev().css('color', 'red');
        }
    });

    $('.rate_form').submit(function (e) {
        const $form = $(this);
        let errors = 0;
        e.preventDefault();
        if (!$('#rate_form_username').val().length) {
            errors += 1;
            $('#rate_form_username').css('border-color', 'red').prev().css('color', 'red');
        }
        $('select', $form).each(function() {
            if (parseInt($(this).val()) === 0) {
                $(this).parent().find('label').css('color', 'red');
                errors += 1;
            } else {
                $(this).parent().find('label').css('color', '');
            }
        });
        if (!errors) {
            $form.parents('.panel').remove();
            $.post($form.attr('action'), $form.serialize(), function (result) {
                $("#rating-modal .message").addClass('d-none').removeClass('d-block');
                if (result.status) {
                    $("#rating-modal .success").removeClass('d-none').addClass('d-block');
                } else {
                    $("#rating-modal .error").removeClass('d-none').addClass('d-block');
                }
                $("#rating-modal").show();
            })
        }
        return false;
    })
});
