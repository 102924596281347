import {apiFetch} from "./apiFetch";

const $ = require('jquery');

const getMaxCacheKey = async () => {
    const re = /^psoflota-v(\d+)$/;
    const keys = (await window.caches.keys()).filter(cacheName => re.test(cacheName)).map(cacheName => parseInt(cacheName.match(re)[1]));
    const maxKey = await apiFetch('/cache-version', {method: 'POST'}).then(response => response.json()).then(response => response.version);
    keys.filter(cacheVersion => cacheVersion < maxKey).forEach(cacheVersion => caches.delete(`psoflota-v${cacheVersion}`));
    return maxKey;
}

(async function() {
    let version = '1.0';
    try {
        const manifest = await fetch('/manifest.json').then(response => response.json());
        version = manifest.version;
        if (typeof version === 'undefined') {
            version = '1.0';
        }
    } catch (err) {
        console.error(err);
    }
    version = `${version}.${await getMaxCacheKey()}`;
    /*
    $('.header-inner, [data-menu]')
        .append(`<span style="font-size: 9px; position: absolute; bottom: 5px; right: 5px;">${version}</span>`);
     */
})();
