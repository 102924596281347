import {_event} from "./_events";
import {PSO} from "./_PSO";
import {RejectionComponent} from "./RejectionComponent";

let internalChange = false;
const $ = require('jquery');
const changePage = (name, changeHash = true) => {
    $('body').removeClass('ready');
    $('.page-active').removeClass('page-active');
    $(`#page-${name}`).addClass('page-active');
    _event.emmit(_event.ON_PAGE_CHANGE, {page: name});
    if (changeHash) {
        internalChange = true;
        location.href = `#${name}`;
        setTimeout(() => {
            internalChange = false;
        }, 200);
    }
}
_event.addListener(_event.ON_PAGE_CHANGE, data => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    $('body').removeClass('ready');
    if (data.page === 'login') {
        $('body').addClass('white').removeClass('background');
        $('#main-container').removeClass('main-container').addClass('h-100');

        setTimeout(() => {
            $('body').removeClass('ready')
        }, 500);

    }  else {
        $('body').removeClass('white').addClass('background');
        $('#main-container').addClass('main-container').removeClass('h-100');

        $(`.menu-button.active, .footer-icon.active`).removeClass('active');
        $(`a[href="#page-${data.page}"]`).addClass('active');

        setTimeout(() => {
            $('body').addClass('ready')
        }, 500);
    }
})

window.onhashchange = e => {
    if (internalChange) {
        internalChange = !internalChange;
    } else {
        const hash = location.hash.match(/^#([^\/]+)/);

        hash && changePage(hash[1], false);
    }
};

$(function() {
    $(`a[href^="#page-"]`).click(function (e) {
        e.preventDefault();
        changePage($(this).attr('href').replace('#page-', ''))
        return false;
    });
});

$('[data-toggle="logout"]').click(async function() {
    await PSO.logout();
    changePage('login');
});
$('[data-toggle="history-back"], [data-toggle="back"]').click(e => {
    e.preventDefault();
    window.history.back();
    return false;
})

export const _navigation = {
    changePage
}
