import {_storage} from "./_storage";
import {_navigation} from "./_navigation";
import {_event} from "./_events";
import {API, PSO} from "./_PSO";
import {Mercure} from "./_mercure";
import * as $ from "jquery";
import {RejectionComponent} from "./RejectionComponent";

(async () => {
    _navigation.changePage(await _storage.getItem('login') ? 'dash' : 'login');
})();

_event.addListener(_event.ON_LOGIN, async () => {
    const savedToken = _storage.getItem('token');
    const cookieToken = $.cookie('remember_me');
    if (cookieToken && cookieToken !== savedToken) {
        await _storage.setItem('token', cookieToken);
    }
    setTimeout(() => {
        navigator && navigator.serviceWorker && navigator.serviceWorker.ready.then( registration => {
            registration.active.postMessage({
                action: 'precache'
            });
        });
    }, 10000);
});

PSO.init();


Mercure.connect();
_event.addListener(_event.ON_EVENT_CHANGE, () => {
    Mercure.disconnect();
    Mercure.connect();
})
_event.addListener(_event.ON_LOGIN, () => {
    Mercure.connect();
    console.log('FCMToken', window.fcmToken);
    if (window.fcmToken) {
        API.asyncPostCall('change-fcm-token',
            {
                oldToken: null,
                newToken: fcmToken
            });
        localStorage.setItem('fcmToken', fcmToken);
    }
})
_event.addListener(_event.ON_VISIBLE, () => {
    Mercure.connect();
})

_event.addListener(_event.ON_PAGE_CHANGE, async (data) => {
    let login = 0;
    if (data.page != 'login') {
        RejectionComponent.init();
        login = parseInt(await _storage.getItem('login'));
    }
    if (login) {
        document.getElementById('main-container').classList.add('logged-in');
    } else {
        document.getElementById('main-container').classList.remove('logged-in');
    }
})

