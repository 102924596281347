// Detects if device is on iOS
import * as $ from "jquery";
import "jquery.cookie";

const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
}
// Detects if device is in standalone mode
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

// Checks if should display install popup notification:
if (isIos() && !isInStandaloneMode()) {
    //alert("Can show prompt");
    //
    if (typeof $.cookie('ios-prompt') === 'undefined') {

        showIosInstall();
    }
}

function showIosInstall() {
    let iosPrompt = document.querySelector(".ios-prompt");
    iosPrompt.style.display = "block";
    iosPrompt.addEventListener("click", () => {
        $.cookie('ios-prompt', true, {expires: 1});
        iosPrompt.style.display = "none";

    });
    setTimeout(() => {
        iosPrompt.classList.add('animate')
    }, 500);
}
