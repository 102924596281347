import {_storage} from "./_storage";

const onTokenChange = token => {
    navigator.serviceWorker.ready.then( registration => {
        registration.active.postMessage({
            action: 'set-token',
            token: token
        });
    });
};

_storage.onItemChange('token', onTokenChange);
_storage.getItem('token').then(onTokenChange);

