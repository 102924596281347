const DEBUG = true;
const ttls = {};
const defaultTtl = 3600000;
const setTtl = (name, ttl) => ttls[name] = ttl;
const getTtl = name => name in ttls ? ttls[name] : defaultTtl;
const callbacks = {};
const listeners = {};
const encode = JSON.stringify;
const decode = value => {
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
}
const initItem = (name, callback, ttl = defaultTtl) => {
    if (typeof callback === 'function') {
        callbacks[name] = callback;
    }
    setTtl(name, ttl);
};
const setItem = async (name, value) => {
    if (typeof value === "undefined") {
        return;
    }
    // if (name === 'token') {

    // }
    DEBUG && console.warn('[STORAGE][SET]', name, encode(value));
    await localStorage.setItem(name, encode(value));
    await localStorage.setItem(`${name}_ttl`, Date.now() + getTtl(name));
    (name in listeners) && listeners[name].map(callback => {
        DEBUG && console.warn('[STORAGE][EXECUTE]', name, encode(value), `${callback}`)
        callback(value)
    });
};
const loadingLocked = {};
const getItem = async (name, _default = null) => {
    let result = _default;
    const ttl = parseInt(await localStorage.getItem(`${name}_ttl`)) || 0;
    if (ttl > Date.now()) {
        result = decode(await localStorage.getItem(name));
    } else if ((name in loadingLocked) && loadingLocked[name]) {
        result = decode(await localStorage.getItem(name));
    } else if (name in callbacks) {
        try {
            loadingLocked[name] = true;
            result = await callbacks[name]();
            await setItem(name, result);
        } catch (err) {
            result = decode(await localStorage.getItem(name));
        }
        loadingLocked[name] = false;
    }
    return result;
};
const removeItem = async name => {
    await localStorage.setItem(`${name}_ttl`, 0);
    DEBUG && console.warn('[STORAGE][REMOVE]', name);
};
const clearWithCallbacks = () => Object.keys(callbacks).map(async item => {
    await removeItem(item);
})
const resetWithCallbacks = () => Object.keys(callbacks).map(async item => {
    await removeItem(item);
    await getItem(item);
})
const initWithCallbacks = () => Object.keys(callbacks).map(async name => await _storage.getItem(name));
const clear = async () => localStorage.clear();
const onItemChange = (name, callback) => {
    if (typeof callback !== 'function') {
        return false;
    }
    if (!(name in listeners)) {
        listeners[name] = [];
    }
    DEBUG && console.warn('[STORAGE][LISTENER]', name, `${callback}`);
    listeners[name].push(callback);
}
export const _storage = {
    initItem,
    setItem,
    getItem,
    removeItem,
    onItemChange,
    resetWithCallbacks,
    clear
}
