const rating_class = rating => {
    let floor = Math.floor(rating);
    let rest = rating - floor;
    if (rest < 0.12) {
        rest = 0;
    } else if (rest < 0.30) {
        rest = 25;
    } else if (rest < 0.70) {
        rest = 50;
    } else if (rest < 0.9) {
        rest = 75;
    } else {
        floor += 1;
        rest = 0;
    }
    return `size--${floor} size--${floor}--${rest}`;
}

export default rating_class;
