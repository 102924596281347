import * as $ from "jquery";
import "jquery.cookie";
import "jquery-mask-plugin";
import "jquery-ui";
import {PSO, API} from "./_PSO.js";
import moment from "moment";
import {_storage} from "./_storage";
import {_event} from "./_events";
import {_navigation} from "./_navigation";
moment.locale('pl');

let Login = {};
Login.step = 1;
Login.$reg_nr = undefined;
Login.$vin = undefined;
Login.$tel_nr = undefined;
Login.tel_nr = undefined;
Login.$auth_code = undefined;
Login.auth_code = undefined;
Login.changeStep = function (how) {
    if (Login.step === how) {
        return;
    }
    $('.step' + Login.step).hide();
    $('.step-animated' + Login.step).removeClass('animate--in animate--before-in').addClass('animate--out');
    setTimeout(function () {
        $('.step-animated' + Login.step).hide();
        if (typeof how === 'boolean') {
            if (how) {
                Login.step++;
            } else {
                Login.step--;
            }
        } else {
            Login.step = how;
        }
        $('.step' + Login.step).show();
        $('.step-animated' + Login.step).removeClass('animate--out').show().addClass('animate--before-in');
        setTimeout(() => $('.step-animated' + Login.step).addClass('animate--in'), 100);
    }, 600)
};
Login.preloadAndRedirect = function () {
    $('.step-animated' + Login.step).removeClass('animate--in animate--before-in').addClass('animate--out');
    PSO.preloader.start();
    $('#login-submit').prop('disabled', true);
    PSO.gotoDash();
};
Login.submit = function () {
    $('#connection-error').hide();
    if (Login.$reg_nr.val() === '' || Login.$vin.val() === '') {
        $('#login-error-data').fadeIn();
        return;
    } else {
        $('#login-error-data').hide();
    }
    PSO.preloader.start();
    API.call('POST', 'login', {
        login: Login.$reg_nr.val(),
        password: Login.$vin.val()
    }, async function (response) {
        await _storage.setItem('token', response.token);
        if (response.status) {
            await PSO.setLogin(true);
            await _storage.setItem('auth', {
                login: 1,
                sms: response.sms,
                rules: response.rules
            });
            if (response.car) {
                let car = await _storage.getItem('car');
                if (car && car.carId != response.car.carId) {
                    PSO.clearCache();
                }

                PSO.car = response.car;
                await _storage.setItem('car', PSO.car);

                // PSO.deposits = response.deposits;
                // localStorage.setItem('deposits', JSON.stringify(PSO.deposits));
                // localStorage.setItem('deposit_refreshed', timestamp);

                // PSO.fleet = response.fleet;
                // await Storage.setItem('fleet', PSO.fleet);

                // await Storage.setItem('reservation', response.reservation);
                // await Storage.removeItem('rejection');
                // await Storage.removeItem('hasReservation');
                // if (await Storage.getItem('hasReservation')) {
                //     Mercure.connect()
                //     if (navigator.serviceWorker) {
                //         navigator.serviceWorker.ready.then( registration => {
                //             registration.active.postMessage({'action' : 'appInit'});
                //         });
                //     }
                // }

            }
            // HistoryStack.clear();
            _event.emmit(_event.ON_LOGIN);
            if (response.sms) {
                PSO.preloader.stop();
                Login.changeStep(2);
            } else if (!response.rules) {
                PSO.preloader.stop();
                Login.changeStep(4);
            } else {
                if (window.location.hash == '#dash') {
                    window.location.href = '/#dash';
                    window.location.reload();
                }
                window.location.href = '/#dash';
            }
        }
    }, function (response) {
        PSO.preloader.stop();
        $('#login-error-data').hide();
        if (response.status === 0) {
            $('#connection-error').fadeIn();
            return;
        }
        if (response.responseJSON.status === false) {
            $('#login-error').fadeIn();
        }
    });
};
Login.sendAuthCodeSMS = function () {
    API.call('POST', 'sms-send', {
        rules: true,
        phone: Login.tel_nr.replace(' ', '')
    });
};
Login.getTelephone = function () {
    let error = false;
    Login.tel_nr = Login.$tel_nr.val();
    $("#phoneNumber").text('Telefon: ' + Login.tel_nr.replace(/[\(\)]/g, ''));
    Login.tel_nr = Login.tel_nr.replace(/\s/g, '').replace('(', '').replace(')', '');
    Login.tel_nr = [Login.tel_nr.slice(0, 3), ' ', Login.tel_nr.slice(3)].join('');
    if (Login.tel_nr.length !== 13) {
        $('#tel-error').fadeIn();
        error = true;
    }
    $(".step-animated2").removeClass('was-validated');
    $("#customCheck1").prop('isvalid', true);
    if (!$("#customCheck1").is(":checked")) {
        $(".step-animated2").addClass('was-validated');
        $("#customCheck1").prop('isvalid', false);
        error = true;
    }
    if (error) {
        return false;
    }
    Login.sendAuthCodeSMS();
    Login.changeStep(true);
    (async function () {
        await _storage.setItem('auth', {
            ...await _storage.getItem('auth', {}),
            rules: true
        });
    })();
};
Login.submitAuthCode = function () {
    PSO.preloader.start();
    if (!Login.$auth_code.val()) {
        $('#auth-code-error').fadeIn();
        PSO.preloader.stop();
        return;
    }
    API.asyncPostCall('sms-validate', {
        code: Login.$auth_code.val(),
        phoneNumber: Login.tel_nr
    }).then(() => {
        Login.changeStep(1);
        $('#connection-error').hide();
        $('#login-error-data').hide();
        (async function () {
            await _storage.setItem('auth', {
                ...await _storage.getItem('auth', {}),
                sms: false
            });
            window.location.href = '/#dash';
        })();
    }).catch(() => {
        PSO.preloader.stop();
        $('#auth-code-error').fadeIn();
    });
};
Login.acceptRules = function() {
    $(".step-animated4").removeClass('was-validated');
    $("#customCheck2").prop('isvalid', true);
    if (!$("#customCheck2").is(":checked")) {
        $(".step-animated4").addClass('was-validated');
        $("#customCheck2").prop('isvalid', false);
        return false;
    }
    API.call('POST', 'set-rules', {
        rules: true
    });
    (async function () {
        await _storage.setItem('auth', {
            ...await _storage.getItem('auth', {}),
            rules: true
        });
        await _navigation.changePage('dash');
    })();
};
Login.init = function () {

    // PSO.gotoDash();

    Login.$reg_nr = $('#reg-nr');
    Login.$vin = $('#vin');
    Login.$tel_nr = $('#tel-nr');
    Login.$auth_code = $('#auth-code');

    $(".step-animated4").removeClass('was-validated');
    $("#customCheck2").prop('isvalid', true);
    $(".step-animated2").removeClass('was-validated');
    $("#customCheck1").prop('isvalid', true);

    Array.from(document.getElementsByTagName('input')).forEach(item => item.style.textTransform = 'uppercase');
    Login.$tel_nr.mask('(+48) 000 000 000');
    Login.$tel_nr.val('(+48)');

    $("input[type=text]").change(function () {
        if (!$(this).val()) {
            $(this).parent().find('label').addClass('placeholder');
        } else {
            $(this).parent().find('label').removeClass('placeholder');
        }
    }).focus(function () {
        $(this).parent().find('label').removeClass('placeholder');
    }).blur(function () {
        $(this).trigger('change');
    }).trigger('change');


    $('.back').click(function () {
        Login.changeStep(false);
        return false;
    });
    $('#login-submit').unbind('click').click(Login.submit);
    $('#request-auth-code').unbind('click').click(Login.getTelephone);
    $('#submit-auth-code').unbind('click').click(Login.submitAuthCode);
    $('#accept-rules').unbind('click').click(Login.acceptRules);

    $('#login-submit').closest('.animate').find('input').on('keyup', e => {
        if (e.keyCode === 13) {
            Login.submit();
        }
    })
    $('#request-auth-code').closest('.animate').find('input').on('keyup', e => {
        if (e.keyCode === 13) {
            Login.getTelephone();
        }
    })
    $('#submit-auth-code').closest('.animate').find('input').on('keyup', e => {
        if (e.keyCode === 13) {
            Login.submitAuthCode();
        }
    })

    $('#resend-sms').click(function () {
        Login.sendAuthCodeSMS();
        $('#resend-sms-modal').fadeIn();
    });
};

const initRules = () => {
    $("#page-login [data-toggle=rules]").unbind('click').click(function(e) {
        e.preventDefault();
        $("#rulesModal").removeClass('d-none').addClass('d-block');
        return false;
    });
    $("#rulesModal .modal-header a").unbind('click').click(function(e) {
        e.preventDefault();
        $("#rulesModal").removeClass('d-block').addClass('d-none');
        return false;
    })
}

_event.addListener(_event.ON_PAGE_CHANGE, async data => {
    if (data.page !== 'login') return;
    $("#page-login input[type=text]").val('')
    $('#page-login input#tel-nr').val('(+48)')
    $('#page-login label[for="customCheck2"] a, #page-login label[for="customCheck1"] a').each(function() {
        $(this).attr('href', $(this).attr('href').replace(/(^[^\?]+).*/, '$1') + '?time=' + Date.now())
    });
    PSO.preloader.start();
    API.asyncGetCall('check-login')
        .then(async response => {
            if (response.status === true) {
                await PSO.setLogin(true);
                await _storage.setItem('auth', {
                    login: 1,
                    sms: response.sms,
                    rules: response.rules
                });
                if (response.sms) {
                    Login.changeStep(2);
                } else if (!response.rules) {
                    Login.changeStep(4);
                } else {
                    Login.changeStep(1);
                    _navigation.changePage('dash');
                }
            } else {
                PSO.logout();
                Login.init();
                Login.changeStep(1);
            }
        })
        .catch(err => {
            PSO.logout();
            Login.init();
            Login.changeStep(1);
        })
        .finally(() => {
            PSO.preloader.stop();
        });
    initRules();
})
_event.addListener(_event.ON_PAGE_CHANGE, async data => {
    if (data.page === 'login') return;
    const auth = await _storage.getItem('auth', {});
    if ("sms" in auth && auth.sms === true) {
        _navigation.changePage('login');
        Login.init();
        Login.changeStep(2);
    } else if ("rules" in auth && auth.rules === false) {
        _navigation.changePage('login');
        Login.init();
        Login.changeStep(4);
    }
})
