// import {Storage} from "./Storage";

import {_event} from "./_events";

require('../css/app.scss');

import "./_storage";
import "./_token";
import "./_events";
import "./_data";
import "./_app";

import "./page_login";
import "./page_dash";
import "./page_car";
import "./page_contact";
import "./page_services";
import "./page_calendar"
import './Menu.js';
import './banner'
import './forms'
import './FCM'
import './version'

window.addEventListener('online', () => _event.emmit(_event.ON_ONLINE));
window.addEventListener('offline', () => _event.emmit(_event.ON_OFFLINE));
document.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'visible') {
        _event.emmit(_event.ON_VISIBLE);
    } else {
        _event.emmit(_event.ON_HIDDEN);
    }
});

_event.addListener(_event.ON_ONLINE, () => {
    document.body.classList.remove('offline');
    Array.from(document.querySelectorAll('[data-offline="disabled"]'))
        .filter(el => el.disabled)
        .forEach(el => {

            el.disabled = parseInt(el.dataset.prevDisabled) === 1;
            if (parseInt(el.dataset.prevClassDisabled) === 1) {
                el.classList.add('disabled');
            } else {
                el.classList.remove('disabled');
            }
        });
});

_event.addListener(_event.ON_OFFLINE, () => {
    document.body.classList.add('offline')
    Array.from(document.querySelectorAll('[data-offline="disabled"]'))
        .forEach(el => {

            el.dataset.prevDisabled= el.disabled ? 1: 0;
            el.dataset.prevClassDisabled = el.classList.contains('disabled') ? 1 : 0;
            el.disabled = true;
            el.classList.add('disabled');
        });
});

