const EvenCallbacks = {};
export const Events = {
    DEBUG: false,
    IS_SW: false,
    CONTROL_TAKEN: false,
    ON_ONLINE: 'on_online',
    ON_OFFLINE: 'on_offline',
    ON_LOGIN: 'on_login',
    ON_LOGOUT: 'on_logout',
    ON_VISIBLE: 'on_visible',
    ON_HIDDEN: 'on_hidden',
    ON_ELEMENT_SET: 'on_element_set',
    ON_ELEMENT_REMOVE: 'on_element_remove',
    ON_ELEMENT_LOADED: 'on_element_loaded',
    ON_INITIALIZE: 'on_initialize',
    ON_CACHE_CLEAR: 'on_cache_clear',
    ON_TERMINATE: 'on_terminate',
    ON_MERCURE_UPDATE: 'on_mercure_update',
    ON_MERCURE_DELETE: 'on_mercure_delete',
    ON_MERCURE_REJECT: 'on_mercure_reject',
    ON_SW_CONTROL_ON: 'sw_control_on',
    ON_SW_CONTROL_OFF: 'sw_control_off',
    initialized: false,
    listenOnElementLoaded: (elementName, cb) => {
        Events.listen(Events.ON_ELEMENT_LOADED, details => {
            if ('name' in details && details.name === elementName) {
                cb(details.value);
            }
        });
    },
    listenOnElementSet: (elementName, cb) => {
        Events.listen(Events.ON_ELEMENT_SET, details => {
            if ('name' in details && details.name === elementName) {

                cb(details.value);
            }
        });
    },
    listenOnElementRemove: (elementName, cb) => {
        Events.listen(Events.ON_ELEMENT_REMOVE, details => {
            if ('name' in details && details.name === elementName) {
                cb();
            }
        });
    },
    listen : (eventName, cb, local = false) => {
        if (Array.isArray(eventName)) {
            return eventName.forEach(_eventName => Events.listen(_eventName, cb));
        }
        if (!(eventName in EvenCallbacks)) {
            EvenCallbacks[eventName] = [];
        }
        Events.DEBUG && console.warn(Events.IS_SW ? '[SW]' : '[FR]', '[Events] addListener', eventName);
        EvenCallbacks[eventName].push({
            cb,
            local
        });
    },
    emmit : (eventName, details = {}, notify = true) => {
        if (!eventName) {
            return;
        }
        Events.DEBUG && console.warn(Events.IS_SW ? '[SW]' : '[FR]', '[Events] emmit', eventName, details, notify);
        if (notify) {
            Events._notify(eventName, details);
        }
        (eventName in EvenCallbacks) && EvenCallbacks[eventName]
            .filter(listener => Events.CONTROL_TAKEN ? listener.local === false : true)
            .forEach(listener => listener.cb(details));
    },
    _notifyServiceworker: (action, eventName = null, details = {}) => {
        navigator && navigator.serviceWorker && navigator.serviceWorker.ready.then( registration => {
            registration.active.postMessage({
                action: action,
                event: eventName,
                details: details
            });
        });
    },
    _notifyFrontend: (action, eventName = null, details = {}) => {
        self && self.clients && self.clients.matchAll({
            includeUncontrolled: true,
            type: 'window',
        }).then((clients) => {
            clients && clients.map(client => client.postMessage({
                action: action,
                event: eventName,
                details: details
            }))
        })
    },
    _notify: (eventName, details) => {
        !Events.IS_SW && Events._notifyServiceworker('event', eventName, details);
        Events.IS_SW && Events._notifyFrontend('event', eventName, details)
    },
    _listen: () => {
        if (!Events.IS_SW) {
            navigator.serviceWorker.onmessage = e => {
                Events.DEBUG && console.warn(Events.IS_SW ? '[SW]' : '[FR]', '[Events] onMessage', e);
                if (('action' in e.data) && e.data.action === 'event') {
                    Events.emmit(e.data.even, e.data.details, false);
                }
                if (('action' in e.data) && e.data.action === 'sw-control') {
                    Events.CONTROL_TAKEN = true;
                    Events.emmit(Events.ON_SW_CONTROL_ON);
                }
            }
        }
        if (Events.IS_SW) {
            self.addEventListener('message', e => {
                Events.DEBUG && console.warn(Events.IS_SW ? '[SW]' : '[FR]', '[Events] onMessage', e);
                if (('action' in e.data) && e.data.action === 'event') {
                    Events.emmit(e.data.even, e.data.details, false);
                }
                if (('action' in e.data) && e.data.action === 'sw-control') {
                    Events._notifyFrontend('sw-control');
                    Events.emmit(Events.ON_SW_CONTROL_ON);
                }
            });
        }
    },
    init: () => {
        Events.IS_SW = typeof window === 'undefined' ? true : false;
        Events._listen();
        if (!Events.IS_SW) {
            navigator.serviceWorker.ready.then( registration => {
                registration.active.postMessage({
                    action: 'sw-control'
                });
            });
        }
    }
}
